import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_SUBSCRIBER_MAILBOX_SERVICE}/subscriber-mailbox-service`);

const subscriberMailboxService = {
    getReceivedSms(params, then, error) {
        service.getRequest(1, 'received-sms',
            params, then, error,
        );
    },
    getReceivedMessagesKafka(params, then, error) {
        service.getRequest(
            1, 'received-messages-kafka',
            params, then, error,
        );
    },
    getReceivedCdrs(params, then, error) {
        service.getRequest(1, 'received-cdrs',
            params, then, error,
        );
    },
    getReceivedPackets(params, then, error) {
        service.getRequest(1, 'received-packets',
            params, then, error,
        );
    },
};

export default subscriberMailboxService;